<template>
  <el-card>
    <div style="margin-bottom: 10px">
      <el-button class="vd_export" size="small" type="info" @click="getPushList()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
    </div>
    <DynamicUTable
      ref="dynamicUTable1"
      :columns="tableProperties"
      :need-check-box="false"
      :need-pagination="false"
      :need-search="true"
      :tableData="tableData"
      @getTableData="getPushList"
    >
      <template v-slot:operation="scope">
        <!--        <el-button size="mini" type="primary" @click="download(scope.row.scon_inv_url)">下载</el-button>-->
        <el-button size="mini" type="success" @click="toCompleted(scope.row)">完成</el-button>
      </template>
    </DynamicUTable>
  </el-card>
</template>

<script>
import bus from '@/components/common/bus';
import { getDate } from '@assets/js/dateUtils';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { sconAPI } from '@api/modules/scon';

export default {
  name: 'inquPush9005',
  components: { DynamicUTable },
  data() {
    return {
      activeName: 'first',
      tableData: [],
      loading: true,
      tableProperties: [
        {
          prop: 'call_time',
          itemType: 'input',
          label: '通知时间',
          input: false,
          sortable: false,
          labelWidth: 180,
          formatter: val => getDate(val, false)
        },
        { prop: 'scon_no', itemType: 'input', label: '销售合同号', input: true, sortable: false, widthAuto: true },
        { prop: 'cust_abbr', itemType: 'input', label: '客户简称', input: true, sortable: false, widthAuto: true },
        { prop: 'scon_cust_no', itemType: 'input', label: '客户订单号', input: true, sortable: false, widthAuto: true },
        { prop: 'scon_total', itemType: 'input', label: '合同金额', input: false, sortable: true, widthAuto: true },
        { label: '操作', prop: 'operation', itemType: 'input', input: false, sortable: false, labelWidth: 220 }
      ]
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getPushList();
    },
    //获取数据
    getPushList() {
      this.loading = true;
      sconAPI.getSconInvPushList(this.$refs.dynamicUTable1.searchForm).then(({ data }) => {
        this.tableData = data.list;
        setTimeout(() => {
          this.loading = false;
        }, 200);
      });
    },
    download(url) {
      window.open(url, '_blank');
    },
    toCompleted(row) {
      this.$confirm('点击完成后，此条消息将会消失!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sconAPI
            .updateSconInvPush({
              scon_invpush_id: row.scon_invpush_id
            })
            .then(({ data }) => {
              this.$message.success('操作成功!');
              this.getPushList();
              //刷新首页推送提醒数量
              bus.$emit('getPermInfo');
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
  margin-top: 10px;
}
</style>
